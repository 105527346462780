body {
  color: #000;
  text-align: center;
  background-color: #dee3e7;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  display: flex;
}

dialog {
  text-align: left;
  min-width: 60%;
  max-width: 600px;
  padding: 0;
  box-shadow: 0 0 6px #444;
}

dialog.big {
  border: none;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  margin: 0;
}

fieldset {
  grid-gap: 1em;
  text-align: center;
  border: 0;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-auto-rows: 1fr;
  justify-content: center;
  padding: 0;
  display: grid;
}

footer {
  font-size: smaller;
}

footer span {
  display: inline-block;
}

a {
  color: #000;
}

nav ul li a {
  -webkit-user-select: none;
  user-select: none;
  padding: .5em 1em;
  text-decoration: none;
  display: block;
}

a:focus {
  color: #fff;
  background-color: #000;
  outline: 1px solid #000;
}

nav ul li a:hover {
  color: #000;
  cursor: pointer;
  background-color: #efefef;
}

#sidebar-toggler-button {
  text-align: center;
  place-items: center;
  width: 2em;
  height: 2em;
  margin-left: 1em;
  padding: 0;
  display: inline-grid;
}

#username {
  margin-right: 1em;
  font-size: small;
}

nav {
  display: none;
  left: -250px;
}

nav.topbar {
  border-radius: .5em;
  flex-direction: row;
  flex-grow: 1;
  place-content: end;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
}

nav.sidebar {
  z-index: -1;
  background-color: #fff;
  flex-direction: column;
  width: 180px;
  height: 100dvh;
  transition: left .5s;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 0 10px #444;
}

nav.shown {
  left: 0;
}

.sidebar #navigation-links {
  flex-grow: 1;
  padding-top: 4em;
}

#supplemental-links {
  flex-grow: 0;
}

h1 {
  flex-grow: 1;
  padding-left: .5em;
  font-size: small;
  display: inline;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav.topbar ul {
  display: inline-block;
}

nav.topbar ul li {
  font-size: small;
  display: inline-block;
}

nav.topbar ul li a {
  border-radius: .5em;
}

nav.sidebar ul li {
  text-align: left;
  border-bottom: 1px inset #000;
  list-style: none;
}

table {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 5px #444;
}

th, td {
  text-align: left;
  border: 1px solid #efefef;
  padding: .6em;
}

th:first-child {
  width: 5%;
}

tr:hover td {
  background-color: beige;
}

legend {
  text-align: center;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: bold;
}

span.icon {
  font-size: 3em;
  display: block;
}

.action-header {
  align-items: center;
  display: flex;
}

.action-header span.icon, tr.log-row span.icon {
  vertical-align: middle;
  padding-right: .2em;
  display: inline-block;
}

.error {
  color: #000;
  background-color: salmon;
}

.title.temporary-status-message {
  color: gray;
}

h2 {
  flex-grow: 1;
  margin-top: 0;
  font-size: 1em;
  display: inline-block;
}

div.entity h2 {
  grid-column: 1 / span all;
}

details {
  display: inline-block;
}

details[open] {
  margin-top: 1em;
  display: block;
}

button, input[type="submit"] {
  color: #000;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #999;
  padding: 1em;
  transition: background-color 1s, color 1s;
  box-shadow: 0 0 6px #aaa;
}

fieldset button {
  border-radius: .7em;
}

action-button {
  flex-direction: column;
  display: flex;
}

action-button button {
  z-index: 1;
  flex-grow: 1;
  width: 100%;
}

span.title {
  display: inline;
}

action-button details {
  flex-grow: 1;
}

action-button details[open] {
  margin-top: 0;
}

action-button details summary div {
  display: inline-flex;
}

action-button details summary div span:first-child {
  flex-grow: 1;
}

.action-button-footer {
  text-align: left;
  font-size: smaller;
  overflow: auto;
}

execution-button {
  margin-top: .2em;
  margin-left: .2em;
  margin-right: .2em;
  display: inline-block;
}

execution-button button {
  margin-top: .2em;
  margin-bottom: .2em;
}

button:hover, input[type="submit"]:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #666;
}

button:focus, input[type="submit"]:focus {
  outline: 1px solid #000;
}

button:disabled, input[type="submit"]:disabled {
  color: #3c3c3c;
  cursor: not-allowed;
  background-color: #cecece;
}

.action-failed {
  background-color: red;
}

.action-success {
  background-color: #32cd32;
}

.action-nonzero-exit {
  background-color: orange;
}

.action-timeout {
  background-color: #0ff;
}

.action-blocked {
  color: #fff;
  background-color: purple;
}

img.logo {
  vertical-align: middle;
  width: 1em;
  height: 1em;
}

main {
  padding: 3.5em 1em 1em;
}

summary {
  cursor: pointer;
}

form div.wrapper {
  text-align: left;
  background-color: #fff;
}

label {
  text-align: right;
  min-width: 20%;
  padding-right: 1em;
  display: inline-block;
}

header {
  text-align: left;
  z-index: 3;
  background-color: #dee3e7;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  padding-top: 1em;
  padding-bottom: .2em;
  display: flex;
  position: fixed;
}

input {
  padding: .6em;
}

input:invalid {
  outline: 2px solid red;
}

form .wrapper span.icon {
  vertical-align: middle;
  display: inline-block;
}

button[name="cancel"]:hover {
  color: #000;
  background-color: salmon;
}

input[name="start"]:hover {
  color: #000;
  background-color: #aceaac;
}

span.argument-description {
  margin-left: 1em;
}

p.argument-wrapper {
  place-items: center;
  display: flex;
}

div.buttons {
  justify-content: end;
  gap: 1em;
  display: flex;
}

td.exit-code {
  text-align: center;
}

input.invalid {
  background-color: salmon;
}

#available-version {
  background-color: #aceaac;
  border-radius: 1em;
  padding: .2em;
}

span.tag {
  background-color: #d3d3d3;
  border-radius: .6em;
  padding: .2em;
}

div.toolbar {
  text-align: left;
  background-color: #efefef;
  border: 1px solid #999;
  border-bottom: 0;
  flex-direction: row;
  padding: .4em;
  display: flex;
}

div.toolbar * {
  margin-right: 1em;
}

div.display {
  border: 1px solid #666;
  border-radius: .7em;
  flex-direction: column;
  place-content: center;
  align-items: center;
  font-size: small;
  display: flex;
  box-shadow: 0 0 6px #aaa;
}

#execution-dialog-xterm {
  overflow: scroll;
}

.padded-content, .xterm {
  padding: 1em;
}

@media screen and (width <= 600px) {
  fieldset {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  label {
    text-align: left;
    min-width: auto;
    margin-bottom: .6em;
    font-weight: bold;
  }

  p.argument-wrapper {
    flex-direction: column;
  }

  span.argument-description {
    margin-top: .6em;
    margin-left: 0;
  }

  dialog {
    border-left: 0;
    border-right: 0;
    width: 100vw;
    max-width: 100vw;
    margin-left: 0;
    margin-right: 0;
  }

  .xterm {
    width: fit-content;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (prefers-color-scheme: dark) {
  body, header {
    color: #fff;
    background-color: #333;
  }

  dialog {
    color: #fff;
    background-color: #222;
  }

  form div.wrapper {
    background-color: #222;
  }

  button, input[type="submit"] {
    color: #fff;
    background-color: #222;
    border: 1px solid #666;
    box-shadow: 0 0 6px #444;
  }

  button:focus, input[type="submit"]:focus {
    outline: 2px solid #72b7f4;
  }

  footer, a, a:visited {
    color: #bbb;
  }

  a:focus {
    color: #000;
    background-color: #72b7f4;
    outline: 1px solid #72b7f4;
  }

  nav.sidebar {
    color: #fff;
    background-color: #111;
  }

  nav ul li a:hover {
    color: #fff;
    background-color: #666;
  }

  button:disabled {
    background-color: #000;
  }

  table, td, th {
    border: 1px solid gray;
  }

  td, tr {
    color: #fff;
    background-color: #222;
  }

  tr:hover td {
    background-color: #666;
  }

  div.toolbar {
    background-color: #000;
  }

  div.display {
    box-shadow: 0 0 6px #444;
  }
}
/*# sourceMappingURL=index.eea63d7b.css.map */
